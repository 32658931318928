import { InvoiceGenerator } from "./invoice/InvoiceGenerator"
import { InvoiceList } from "./invoice/InvoiceList"
import PostData from "./invoice/PostData"
import { CountDownTimer } from "./timers/CountDownTimer"

export const PanelCenter = () => {
    return <>
        Center Panel
        {/* <CountDownTimer /> */}

        <PostData />
        {/* <InvoiceGenerator />
        <InvoiceList /> */}



        {/* <h4 className={`current-location-title ${selectedColor}`}>
            {centerMode}
            {usingIframe && (
                <span>
                    <a className="links-for-center" onClick={freezeCenter}>Freeze</a>
                    <a className="links-for-center" onClick={changeCenter}>Change</a>
                </span>
            )}
        </h4>
        {usingIframe && (
            <div>
                {refreshForcer ? (
                    <div className="width-100-percent">
                        {iframeSrcArrayCurrentLocation.map((item, i) => (
                            <div key={i} className={currentFrame === i + 1 ? '' : 'hide'}>
                                <iframe className="width-100-percent" src={item.src}></iframe>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="width-100-percent">
                        {iframeSrcArrayCurrentLocation.map((item, i) => (
                            <div key={i} className={currentFrame === i + 1 ? '' : 'hide'}>
                                <iframe className="width-100-percent" src={item.src}></iframe>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )}
        {!usingIframe && (
            <div>
                {centerMode === 'Invoice' && 'invoice'}
                {centerMode === 'Kid Words' && (
                    <table width="100%" border="1">
                        <tr>
                            <th>#</th>
                            <th>Word</th>
                            <th>Right</th>
                            <th>Wrong</th>
                            <th>Actions</th>
                        </tr>
                        {cloneWOD.map((item, i) => (
                            <tr key={i}>
                                <td>{i}</td>
                                <td>{item}</td>
                                <td></td>
                                <td></td>
                                <td><a className="white-link" href="">Delete</a></td>
                            </tr>
                        ))}
                    </table>
                )}
                {centerMode === 'Nicole' && (
                    <div>
                        <table border="1">
                            <tr>
                                <td>Add Client:</td>
                                <td>
                                    <input type="textbox" value={paidWhat} id="what" name="what" onChange={(e) => setPaidWhat(e.target.value)} />
                                </td>
                                <td><button>Add</button></td>
                            </tr>
                        </table>
                        <table border="1" width="100%">
                            <tr>
                                <th>Client</th>
                                <th>Rate</th>
                                <th>Actions</th>
                            </tr>
                            <tr>
                                <td>
                                    <select className="custom-select" name="option" id="option" value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                                        <option selected>Please Select...</option>
                                        {appService.listOfMoneyOptions.map((item) => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="custom-select" name="option" id="option" value={selectedRate} onChange={(e) => setSelectedRate(e.target.value)}>
                                        <option selected>Please Select...</option>
                                        {appService.listOfMoneyOptions.map((item) => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <button>Start</button>
                                    <button>Stop</button>
                                </td>
                            </tr>
                        </table>
                        <table border="1" width="100%">
                            <tr>
                                <th>Client</th>
                                <th>Rate</th>
                                <th>Time</th>
                                <th>Total</th>
                                <th>Date</th>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                    </div>
                )}
                {centerMode === 'Money' && (
                    <div>
                        <br />
                        <div className="money-totals">
                            Nic: ${totalForNic.toFixed(2).toString()} Will: ${totalForWil.toFixed(2).toString()} - {totalDif}
                        </div>
                        <br />
                        
                    </div>
                )}
            </div>
        )} */}
    </>
}
{/* Rest of the Money section missing */}
import { Counters } from "./counters/Counters"

export const PanelTop = () => {
    return <div className="top-buttons">
        {/* {allCounters && (
            <span>
                {allCounters.map((item) => (
                    <div key={item.code}>
                        <button onClick={() => buttonPress(item.code)}
                            className={selectedColor === 'teal' ? 'color-teal' : selectedColor === 'red' ? 'color-red' : selectedColor === 'blue' ? 'color-blue' : 'color-purple'}>
                            {item.title}
                        </button>
                        <span className='count-outputs'>{item.value}</span>
                    </div>
                ))}
            </span>
        )}
        <span className="count-down-timer">
            <table>
                <tr>
                    <td>Screen change: {countDownVar}</td>
                </tr>
                <tr>
                    <td>Refresh info: {countDown2Var}</td>
                </tr>
            </table>
        </span> */}
        <Counters />
        Panel Top
    </div>
}
import { PanelCenter } from '../panel-center/PanelCenter';
import { PanelLeft } from '../panel-left/PanelLeft';
import { PanelRight } from '../panel-right/PanelRight';
import { PanelTop } from '../panel-top/PanelTop';
import './main.scss';

export const Main = () => {
  return (
    <div>
      {/* <div className="fhc-logo-mode" onClick={closeLogoMode}></div> */}
      <div className="grid-container">
        <div className="header"><PanelTop /></div>
        <div className="menu"><PanelLeft /></div>
        <div className="main"><PanelCenter /></div>
        <div className="right"><PanelRight /></div>
        <div className="footer"></div>
      </div>
    </div>
  );
}

// Implement event handlers like `buttonPress`, `closeLogoMode`, and the missing logic as needed.

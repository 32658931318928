import { ApolloClient, InMemoryCache } from '@apollo/client';

export const client = new ApolloClient({
  
  uri: 'https://exec.artificial.opalstacked.com/graphql/', // Update with your GraphQL server endpoint
  // uri: 'http://localhost:8000/graphql/', // Update with your GraphQL server endpoint
  cache: new InMemoryCache(),
});

export const getCsrfToken = async (): Promise<string> => {
  const response = await fetch('https://exec.artificial.opalstacked.com/get-csrf-token/');
  // const response = await fetch('http://localhost:8000/get-csrf-token/');
  const data = await response.json();
  return data.csrfToken;
};

import { Todos } from "../todos/Todos"
import { AnalogClock } from "./clocks/AnalogClock"
import { RoundClock } from "./clocks/RoundClock"

export const PanelLeft = () => {
    return <>
        Panel Left
        {/* <div className="width-100-percent">
            <div className={`clock-container ${selectedColor}`}>
                <div className="time">{date}</div>
                <div className="date">{dateString}</div>
            </div>
            <div className="white-font money-wrapper">
                <div>
                    <span className="wod" onClick={getNewWOD}>{WOD}</span>
                </div>
                <div>
                    <span className="wod" onClick={getNewMathProblem}>{mathProblem}</span>
                </div>
            </div>
            <Todos />
        </div> */}
        <AnalogClock />
        <Todos />
    </>
}

export const PanelRight = () => {
    return <>
        Panel Right
    {/* <table className="table-right-side">
      <tr>
        <td>
          <button className="button-right-side" onClick={closeFullscreen} style={isFullScreenMode ? { display: 'block' } : { display: 'none' }}>
            Close Fullscreen
          </button>
          <button className="button-right-side" onClick={openFullscreen} style={!isFullScreenMode ? { display: 'block' } : { display: 'none' }}>
            Open Fullscreen
          </button>
        </td>
        <td>
          <button className="button-right-side" onClick={toggleCenterFreeze}>
            {isCenterFrozen ? 'Unfreeze' : 'Freeze'}
          </button>
        </td>
        <td>
          <button className="button-right-side" onClick={toggleShowInfo}>
            {isShowInfo ? 'Hide Info' : 'Show Info'}
          </button>
        </td>
      </tr>
    </table>
    {isShowInfo && (
      <div className="info-box">
        <h2>Events</h2>
        <div className="events-box">
          <div className="event-row">
            {arrayOfTitles.map((title, index) => (
              <div key={index} className="event-title">
                {title}
              </div>
            ))}
          </div>
          <div className="event-row">
            {arrayOfEvents.map((event, index) => (
              <div key={index} className="event-item">
                {event}
              </div>
            ))}
          </div>
        </div>
      </div>
    )} */}
  </>
}
// Post.tsx

import React, { useState } from 'react';
import { useQuery, useMutation, useLazyQuery, gql } from '@apollo/client';
import { client, getCsrfToken } from '../../../apolloClient';


const GET_POSTS = gql`
  query GetPosts {
    posts {
      id
      tags
      html
      created
      by
      status
      title
      featured
    }
  }
`;

const CREATE_POST = gql`
  mutation CreatePost($input: PostInput!) {
    createPost(input: $input) {
      id
      title
    }
  }
`;

const GET_POSTS_BY_TAG = gql`
  query GetPostsByTag($tag: String!) {
    postsByTag(tag: $tag) {
      id
      title
    }
  }
`;

interface PostData {
  tags: string;
  html: string;
  by: string;
  status: string;
  title: string;
  featured: boolean;
}

const Post: React.FC = () => {

  const [csrfToken, setCsrfToken] = useState<string | null>(null);
  const [postData, setPostData] = useState<PostData>({
    tags: '',
    html: '',
    by: '',
    status: '',
    title: '',
    featured: false,
  });

  const { loading, error, data: postsData } = useQuery(GET_POSTS);
  const [createPost] = useMutation(CREATE_POST);
  const [getPostsByTag, { data: postsByTagData }] = useLazyQuery(GET_POSTS_BY_TAG);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setPostData((prevData) => ({ ...prevData, [name]: value }));
  };

  // const handleCreatePost = async () => {
  //   await createPost({ variables: { input: postData } });
  //   setPostData({
  //     tags: '',
  //     html: '',
  //     by: '',
  //     status: '',
  //     title: '',
  //     featured: false,
  //   });
  // };


  // const headers = {
  //   'Content-Type': 'application/json',
  //   'X-CSRFToken': csrfToken, // Include CSRF token here
  // };

  // // Use headers in your GraphQL request
  // fetch('http://localhost:8000/graphql/', {
  //   method: 'POST',
  //   headers: headers,
  //   body: JSON.stringify({ query: 'your GraphQL query here' }),
  // });


  // const headers = {
  //   'Content-Type': 'application/json',
  //   'X-CSRFToken': csrfToken, // Include CSRF token here
  // };

  // // Use headers in your GraphQL request
  // fetch('http://localhost:8000/graphql/', {
  //   method: 'POST',
  //   headers: headers,
  //   body: JSON.stringify({ query: 'your GraphQL query here' }),
  // });



  // const handleCreatePost = async () => {
  //   try {
  //     // Fetch the CSRF token
  //     const csrfToken = await getCsrfToken();


  //     console.log(csrfToken, postData);
  //     // Call the createPost mutation with the CSRF token in the headers
  //     await createPost({
  //       variables: { input: postData },
  //       context: {
  //         headers: {
  //           'X-CSRFToken': csrfToken || '',
  //         },
  //       },
  //     });

  //     // Reset the postData after a successful mutation
  //     setPostData({
  //       tags: '',
  //       html: '',
  //       by: '',
  //       status: '',
  //       title: '',
  //       featured: false,
  //     });
  //   } catch (error) {
  //     // Handle any errors that may occur during the mutation
  //     console.error('Error creating post:', error);
  //   }
  // };

  const example1 = async () => {
    const graphqlEndpoint = ' https://exec.artificial.opalstacked.com/graphql/';

    const query = `
    mutation CreatePost($tags: String!, $html: String!, $by: String!, $status: String!, $title: String!, $featured: Boolean!, $data: JSONString) {
      createPost(tags: $tags, html: $html, by: $by, status: $status, title: $title, featured: $featured, data: $data) {
        post {
          id
          tags
          html
          by
          status
          title
          featured
          data
        }
      }
    }
  `;

    const variables = {
      tags: 'your-tags',
      html: 'your-html-content',
      by: 'your-username',
      status: 'your-status',
      title: 'your-title',
      featured: true, // or false
      data: { key: 'value' }, // optional JSON data
    };

    try {
      const response = await fetch(graphqlEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query,
          variables,
        }),
      });

      // const result = await response.json();
      const result = await response;
      console.log('GraphQL response:', result);

      // if (result.errors) {
      //   console.error('GraphQL mutation error:', result.errors);
      // } else {
      //   console.log('Post created successfully:', result.data.createPost.post);
      // }
    } catch (error) {
      console.error('Error during GraphQL mutation:', error);
    }
  }

  const handleCreatePost = async () => {


    try {
      // Fetch the CSRF token
      const csrfToken = await getCsrfToken();

      console.log(csrfToken, postData);

      // Prepare the request options
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken || '',
        },
        body: JSON.stringify({
          query: `
          mutation CreatePost($input: PostData) {
            createPost(input: $input) {
              // Include the fields you want in the response
              // For example:
              id
              title
            }
          }
        `,
          variables: {
            input: {
              by: "",
              featured: false,
              html: "",
              status: "",
              tags: "",
              title: ""
            },
          },
        }),
        credentials: 'include' as RequestCredentials,
      };

      // Make the request
      const response = await fetch('https://exec.artificial.opalstacked.com/graphql/', requestOptions);
      const responseData = await response.json();

      // Handle the response data as needed
      console.log('Response:', responseData);

      // Reset the postData after a successful mutation
      setPostData({
        tags: '',
        html: '',
        by: '',
        status: '',
        title: '',
        featured: false,
      });
    } catch (error) {
      // Handle any errors that may occur during the mutation
      console.error('Error creating post:', error);
    }
  };



  const getPosts = async () => {
    try {
      const { data } = await client.query({
        query: GET_POSTS,
      });
      return data;
    } catch (error) {
      throw error;
    }
  };

  const handleGetPosts = async () => {
    try {
      const { data } = await getPosts();
      // Handle the data using postsData
      console.log(data);
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const handleGetPostsByTag = async () => {
    await getPostsByTag({ variables: { tag: postData.tags } });
    // Handle the data using postsByTagData
  };

  return (
    <div>
      <h1>Create Post</h1>
      <div>
        <label>Tags:</label>
        <input type="text" name="tags" value={postData.tags} onChange={handleChange} />
      </div>
      <div>
        <label>HTML:</label>
        <textarea name="html" value={postData.html} onChange={handleChange} />
      </div>
      <div>
        <label>By:</label>
        <input type="text" name="by" value={postData.by} onChange={handleChange} />
      </div>
      <div>
        <label>Status:</label>
        <input type="text" name="status" value={postData.status} onChange={handleChange} />
      </div>
      <div>
        <label>Title:</label>
        <input type="text" name="title" value={postData.title} onChange={handleChange} />
      </div>
      <div>
        <label>Featured:</label>
        <input
          type="checkbox"
          name="featured"
          checked={postData.featured}
          onChange={() => setPostData((prevData) => ({ ...prevData, featured: !prevData.featured }))}
        />
      </div>
      <button onClick={handleCreatePost}>Create Post</button>

      <h1>Get Posts</h1>
      <button onClick={handleGetPosts}>Get All Posts</button>

      <h1>Get Posts By Tag</h1>
      <div>
        <label>Tags:</label>
        <input type="text" name="tags" value={postData.tags} onChange={handleChange} />
      </div>
      <button onClick={handleGetPostsByTag}>Get Posts By Tag</button>

      <h1>All Posts</h1>
      <ul>
        {postsData?.posts.map((post: any) => (
          <li key={post.id}>{post.title}</li>
        ))}
      </ul>
    </div>
  );
};

export default Post;

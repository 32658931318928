import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export const AnalogClock = () => {
//   const clockContainer = useRef(null);

//   useEffect(() => {
//     const width = 200;
//     const height = 200;
//     const radius = Math.min(width, height) / 2;

//     const svg = d3
//       .select(clockContainer.current)
//       .append('svg')
//       .attr('width', width)
//       .attr('height', height)
//       .append('g')
//       .attr('transform', `translate(${width / 2},${height / 2})`);

//     const face = svg
//       .append('g')
//       .attr('class', 'face')
//       .selectAll('circle')
//       .data([1])
//       .enter()
//       .append('circle')
//       .attr('r', radius)
//       .style('fill', 'white')
//       .style('stroke', 'black')
//       .style('stroke-width', '2px');

//     const hourHand = svg
//       .append('g')
//       .attr('class', 'hour-hand')
//       .style('stroke', 'black')
//       .style('stroke-width', '4px');

//     const minuteHand = svg
//       .append('g')
//       .attr('class', 'minute-hand')
//       .style('stroke', 'black')
//       .style('stroke-width', '2px');

//     const secondHand = svg
//       .append('g')
//       .attr('class', 'second-hand')
//       .style('stroke', 'red')
//       .style('stroke-width', '1px');

//     function updateClock() {
//       const now = new Date();
//       const hour = now.getHours();
//       const minute = now.getMinutes();
//       const second = now.getSeconds();

//       const hourAngle = (hour % 12) * 360 + (minute / 60) * 30;
//       const minuteAngle = minute * 6 + (second / 60) * 6;
//       const secondAngle = second * 6;

//       hourHand.attr('transform', `rotate(${hourAngle})`);
//       minuteHand.attr('transform', `rotate(${minuteAngle})`);
//       secondHand.attr('transform', `rotate(${secondAngle})`);
//     }

//     setInterval(updateClock, 1000);

//     updateClock();
//   }, []);

//   return (
//     <div ref={clockContainer} className="clock-container">
//       {/* Clock will be rendered inside this container */}
//     </div>
//   );



// const svgRef = useRef<SVGSVGElement>(null);

//   useEffect(() => {
//     if (svgRef.current) {
//       const width = 200;
//       const height = 200;
//       const radius = Math.min(width, height) / 2;

//       const svg = d3.select(svgRef.current)
//         .attr('width', width)
//         .attr('height', height)
//         .append('g')
//         .attr('transform', `translate(${width / 2},${height / 2})`);

//       const face = svg.append('circle')
//         .attr('r', radius)
//         .attr('fill', 'white')
//         .attr('stroke', 'black')
//         .attr('stroke-width', 2);

//       const hands = svg.append('g');

//       function updateClock() {
//         const now = new Date();
//         const second = now.getSeconds();
//         const minute = now.getMinutes();
//         const hour = now.getHours() % 12 + minute / 60;

//         const secondAngle = (second / 60) * 360;
//         const minuteAngle = (minute / 60) * 360;
//         const hourAngle = (hour / 12) * 360;

//         hands.selectAll('*').remove();

//         // Hour hand
//         hands.append('line')
//           .attr('x1', 0)
//           .attr('y1', 0)
//           .attr('x2', 0)
//           .attr('y2', -radius * 0.4)
//           .attr('stroke', 'black')
//           .attr('stroke-width', 4)
//           .attr('transform', `rotate(${hourAngle})`);

//         // Minute hand
//         hands.append('line')
//           .attr('x1', 0)
//           .attr('y1', 0)
//           .attr('x2', 0)
//           .attr('y2', -radius * 0.7)
//           .attr('stroke', 'black')
//           .attr('stroke-width', 3)
//           .attr('transform', `rotate(${minuteAngle})`);

//         // Second hand
//         hands.append('line')
//           .attr('x1', 0)
//           .attr('y1', 0)
//           .attr('x2', 0)
//           .attr('y2', -radius * 0.8)
//           .attr('stroke', 'red')
//           .attr('stroke-width', 2)
//           .attr('transform', `rotate(${secondAngle})`);
//       }

//       setInterval(updateClock, 1000);
//       updateClock();
//     }
//   }, []);

//   return (
//     <svg ref={svgRef} className="clock-container">
//       {/* The clock will be rendered inside this SVG element */}
//     </svg>
//   );


// const svgRef = useRef<SVGSVGElement>(null);

//   useEffect(() => {
//     if (svgRef.current) {
//       const width = 200;
//       const height = 200;
//       const radius = Math.min(width, height) / 2;

//       const svg = d3.select(svgRef.current)
//         .attr('width', width)
//         .attr('height', height)
//         .append('g')
//         .attr('transform', `translate(${width / 2},${height / 2})`);

//       const face = svg.append('circle')
//         .attr('r', radius)
//         .attr('fill', 'white')
//         .attr('stroke', 'black')
//         .attr('stroke-width', 2);

//       const hands = svg.append('g');

//       // Create numbers
//       for (let i = 1; i <= 12; i++) {
//         const angle = (i - 3) * 30; // 30 degrees per hour
//         const x = radius * 0.75 * Math.cos((angle * Math.PI) / 180);
//         const y = radius * 0.75 * Math.sin((angle * Math.PI) / 180);

//         svg.append('text')
//           .attr('x', x)
//           .attr('y', y)
//           .attr('dy', '0.35em')
//           .attr('text-anchor', 'middle')
//           .text(i.toString());
//       }

//       function updateClock() {
//         const now = new Date();
//         const second = now.getSeconds();
//         const minute = now.getMinutes();
//         const hour = now.getHours() % 12 + minute / 60;

//         const secondAngle = (second / 60) * 360;
//         const minuteAngle = (minute / 60) * 360;
//         const hourAngle = (hour / 12) * 360;

//         hands.selectAll('*').remove();

//         // Hour hand
//         hands.append('line')
//           .attr('x1', 0)
//           .attr('y1', 0)
//           .attr('x2', 0)
//           .attr('y2', -radius * 0.4)
//           .attr('stroke', 'black')
//           .attr('stroke-width', 4)
//           .attr('transform', `rotate(${hourAngle})`);

//         // Minute hand
//         hands.append('line')
//           .attr('x1', 0)
//           .attr('y1', 0)
//           .attr('x2', 0)
//           .attr('y2', -radius * 0.7)
//           .attr('stroke', 'black')
//           .attr('stroke-width', 3)
//           .attr('transform', `rotate(${minuteAngle})`);

//         // Second hand
//         hands.append('line')
//           .attr('x1', 0)
//           .attr('y1', 0)
//           .attr('x2', 0)
//           .attr('y2', -radius * 0.8)
//           .attr('stroke', 'red')
//           .attr('stroke-width', 2)
//           .attr('transform', `rotate(${secondAngle})`);
//       }

//       setInterval(updateClock, 1000);
//       updateClock();
//     }
//   }, []);

//   return (
//     <svg ref={svgRef} className="clock-container">
//       {/* The clock will be rendered inside this SVG element */}
//     </svg>
//   );




const svgRef = useRef<SVGSVGElement>(null);

  const updateClock = () => {
    if (svgRef.current) {
      const width = 200;
      const height = 200;
      const radius = Math.min(width, height) / 2;

      const svg = d3.select(svgRef.current)
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(${width / 2},${height / 2})`);

      const face = svg.append('circle')
        .attr('r', radius)
        .attr('fill', 'white')
        .attr('stroke', 'black')
        .attr('stroke-width', 2);

      const hands = svg.append('g');

      // Create numbers
      for (let i = 1; i <= 12; i++) {
        const angle = (i - 3) * 30; // 30 degrees per hour
        const x = radius * 0.75 * Math.cos((angle * Math.PI) / 180);
        const y = radius * 0.75 * Math.sin((angle * Math.PI) / 180);

        svg.append('text')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', '0.35em')
          .attr('text-anchor', 'middle')
          .text(i.toString());
      }

      const now = new Date();
      const second = now.getSeconds();
      const minute = now.getMinutes();
      const hour = now.getHours() % 12 + minute / 60;

      const secondAngle = (second / 60) * 360;
      const minuteAngle = (minute / 60) * 360;
      const hourAngle = (hour / 12) * 360;

      hands.selectAll('*').remove();

      // Hour hand
      hands.append('line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -radius * 0.4)
        .attr('stroke', 'black')
        .attr('stroke-width', 4)
        .attr('transform', `rotate(${hourAngle})`);

      // Minute hand
      hands.append('line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -radius * 0.7)
        .attr('stroke', 'black')
        .attr('stroke-width', 3)
        .attr('transform', `rotate(${minuteAngle})`);

      // Second hand
      hands.append('line')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -radius * 0.8)
        .attr('stroke', 'red')
        .attr('stroke-width', 2)
        .attr('transform', `rotate(${secondAngle})`);
    }
  };

  useEffect(() => {
    updateClock();
    setInterval(updateClock, 1000);
  }, []);

  return (
    <svg ref={svgRef} className="clock-container">
      {/* The clock will be rendered inside this SVG element */}
    </svg>
  );

};

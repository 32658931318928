import { useEffect, useState } from 'react';
import './counters.scss';
import ApiService from '../../serviceApi';
import { listOfCounters } from '../../serviceData';

interface iSourceData {
    id: string;
    updated_at: string;
    title: string;
    status: string;
    featured: boolean;
    updated_at_formatted: string;
    title_formatted: string;
}

interface iCounterButton {
    id: number;
    title: string;
    value: number;
    code: string;
}

interface iPostToTarget {
    featured: boolean;
    status: string;
    title: string;
    html: string; 
    tags: string[];
}

export const Counters = () => {
    const [buttons, setButtons] = useState<iCounterButton[]>([]);
    const [selectedColor] = useState<string>("teal");
    const apiService = new ApiService();

    // const addTodo = () => {
    //     const newTodo = { "todo": { "id": todos.length + 1, "title": inputTitle, "completed": false }, "id": 1 }
    //     apiService.addObject('todos', newTodo)
    //         .then(data => {
    //             getAllTodos();
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //         });
    //     setInputTitle('');

    // };

    // const toggleTodo = (id: number) => {
    //     const updatedTodos = todos.map((todo) => {
    //         if (todo.id === id) {
    //             return { ...todo, completed: !todo.completed };
    //         }
    //         return todo;
    //     });
    //     setTodos(updatedTodos);
    // };

    // const deleteTodo = (id: number) => {
    //     apiService.delete('todos', id)
    //         .then(data => {
    //             getAllTodos();
    //         })
    //         .catch(error => {
    //             console.error('Error:', error);
    //         });
    // };

    const extractPageValues = (arrayOfEvents: iSourceData[]) => {
        for (let index = 0; index < arrayOfEvents.length; index++) {
            var today = new Date(arrayOfEvents[index].updated_at);
            arrayOfEvents[index].updated_at_formatted = today.toLocaleTimeString();
            arrayOfEvents[index].title_formatted = capitalizeFirstLetter(arrayOfEvents[index].title.split('-').join(' '));
        }

        const allCounters: iCounterButton[] = [];

        for (let index = 0; index < listOfCounters.length; index++) {
            allCounters.push(
                {
                    id: index,
                    title: listOfCounters[index],
                    value: arrayOfEvents.filter((post: any) => post.title === listOfCounters[index].split(' ').join('-')).length,
                    code: listOfCounters[index].split(' ').join('-')
                }
            );
        }
        setButtons(allCounters);
    }

    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const getAllCounters = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        //we need to change this to 4am for some crazy reason
        var dateString = yyyy + '-' + mm + '-' + dd + ' 04:00:00';

        const url = `posts/date`;

        let info = {
            date: dateString
        };

        apiService.addObject(url, info)
            .then(data => {
                extractPageValues(data as iSourceData[]);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        getAllCounters();
    }, []);

    const buttonPress = (code: string): void => {
        const info = {
            post: {
                featured: true,
                status: "published", 
                title: code, 
                html: code, 
                tags: [code, "buttons"],
            }
        }

        const url = `posts`;
        apiService.addObject<any>(url, info)
        .then(data => {
            getAllCounters();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    return <div className="top-buttons">
        {buttons && (
            <span>
                {buttons.map((item) => (
                    <span key={item.code}>
                        <button onClick={() => buttonPress(item.code)}
                            className={selectedColor === 'teal' ? 'color-teal' : selectedColor === 'red' ? 'color-red' : selectedColor === 'blue' ? 'color-blue' : 'color-purple'}>
                            {item.title}
                        </button>
                        <span className='count-outputs'>{item.value}</span>
                    </span>
                ))}
            </span>
        )}
        <span className="count-down-timer">
            <table>
                <tr>
                    <td>
                        {/* Screen change: {countDownVar} */}
                    </td>
                </tr>
                <tr>
                    <td>
                        {/* Refresh info: {countDown2Var} */}
                    </td>
                </tr>
            </table>
        </span>
    </div>
}
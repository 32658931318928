// export const ApiService = () => {
//   const apiUrl = 'Replace';
//   const publicCMS = 'Replace';

//   const getAll = async <T>(table: string): Promise<T> => {
//     const url = `${apiUrl}/${table}`;
//     const response = await fetch(url);
//     if (!response.ok) {
//       throw new Error(`Failed to fetch data for table ${table}`);
//     }
//     const data = await response.json();
//     return data;
//   };

//   const addObject = async <T>(table: string, itemName: any): Promise<T> => {
//     const url = `${apiUrl}/${table}`;
//     const response = await fetch(url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(itemName),
//     });
//     if (!response.ok) {
//       throw new Error(`Failed to add object to table ${table}`);
//     }
//     const data = await response.json();
//     return data;
//   };

//   const getTagFromCMS = async <T>(tag: string): Promise<T> => {
//     const url = `${publicCMS}${tag}`;
//     const response = await fetch(url);
//     if (!response.ok) {
//       throw new Error(`Failed to fetch data for tag ${tag}`);
//     }
//     const data = await response.json();
//     return data;
//   };

//   const deleteByTime = async <T>(table: string): Promise<any> => {
//     const url = `${apiUrl}/${table}`;
//     const response = await fetch(url, {
//       method: 'DELETE',
//     });
//     if (!response.ok) {
//       throw new Error(`Failed to delete data for table ${table}`);
//     }
//     return response.status; // Returns the HTTP status code
//   };

//   const deleteItem = async <T>(table: string, id: number): Promise<any> => {
//     const url = `${apiUrl}/${table}/${id}`;
//     const response = await fetch(url, {
//       method: 'DELETE',
//     });
//     if (!response.ok) {
//       throw new Error(`Failed to delete data for table ${table} with ID ${id}`);
//     }
//     return response.status; // Returns the HTTP status code
//   };

//   return (
//     <div>
//       {/* You can use the functions within your component */}
//     </div>
//   );
// };

// ssh artificial@opal7.opalstack.com Wg8Ln7Fq4C!@#$
//sftp artificial@opal7.opalstack.com Wg8Ln7Fq4C!@#$
class ApiService {
    private apiUrl = 'http://master-api.artificial2.opalstacked.com'; // Replace with your API URL
    private publicCMS = `http://master.artificial2.opalstacked.com/ghost/api/v3/content/posts/?key=b70794c5eee06ea85b20a72d47&fields=title,html,id,published_at&limit=1000&filter=tag:`;
  
    async getAll<T>(table: string): Promise<T> {
      const url = `${this.apiUrl}/${table}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch data for table ${table}`);
      }
      const data = await response.json();
      return data;
    }
  
    async addObject<T>(table: string, itemName: any): Promise<T> {
      const url = `${this.apiUrl}/${table}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(itemName),
      });
      if (!response.ok) {
        throw new Error(`Failed to add object to table ${table}`);
      }
      const data = await response.json();
      return data;
    }
  
    async getTagFromCMS<T>(tag: string): Promise<T> {
      const url = `${this.publicCMS}${tag}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch data for tag ${tag}`);
      }
      const data = await response.json();
      return data;
    }
  
    async deleteByTime<T>(table: string): Promise<any> {
      const url = `${this.apiUrl}/${table}`;
      const response = await fetch(url, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`Failed to delete data for table ${table}`);
      }
      return response.status; // Returns the HTTP status code
    }
  
    async delete<T>(table: string, id: number): Promise<any> {
      const url = `${this.apiUrl}/${table}/${id}`;
      const response = await fetch(url, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`Failed to delete data for table ${table} with ID ${id}`);
      }
      return response.status; // Returns the HTTP status code
    }
  }
  
  export default ApiService;








  
  // import { Observable } from 'rxjs';
// import { map } from 'rxjs/operators';
// import { HttpClient } from '@angular/common/http';

// class ApiService {
//   private apiUrl = 'http://your-api-url'; // Replace with your API URL
//   private publicCMS = `http://master.artificial2.opalstacked.com/ghost/api/v3/content/posts/?key=b70794c5eee06ea85b20a72d47&fields=title,html,id,published_at&limit=1000&filter=tag:`;

//   constructor(private http: HttpClient) {}

//   getAll<T>(table: string): Observable<T> {
//     const url = `${this.apiUrl}/${table}`;
//     return this.http.get<T>(url, { observe: 'response' }).pipe(
//       map((res) => res.body)
//     );
//   }

//   addObject<T>(table: string, itemName: any): Observable<T> {
//     const url = `${this.apiUrl}/${table}`;
//     return this.http.post<T>(url, itemName, { observe: 'response' }).pipe(
//       map((res) => res.body)
//     );
//   }

//   getTagFromCMS<T>(tag: string): Observable<T> {
//     const url = `${this.publicCMS}${tag}`;
//     return this.http.get<T>(url, { observe: 'response' }).pipe(
//       map((res) => res.body)
//     );
//   }

//   deleteByTime<T>(table: string): Observable<any> {
//     const url = `${this.apiUrl}/${table}`;
//     return this.http.delete<T>(url, { observe: 'response' }).pipe(
//       map((res) => res)
//     );
//   }

//   delete<T>(table: string, id: number): Observable<any> {
//     const url = `${this.apiUrl}/${table}/${id}`;
//     return this.http.delete<T>(url, { observe: 'response' }).pipe(
//       map((res) => res)
//     );
//   }
// }

// export default ApiService;

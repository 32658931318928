// export const serviceData = () => {


//   iframeSrcArray = ['http://172.16.42.3:1880/ui/#!/0','http://172.16.42.3:1880/ui/#!/1','https://embed.windy.com/embed2.html?lat=40.182&lon=-75.148&detailLat=40.182&detailLon=-75.148&width=650&height=450&zoom=5&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1',
// 'https://embed.windy.com/embed2.html?lat=39.992&lon=-75.440&detailLat=40.182&detailLon=-75.148&width=650&height=450&zoom=9&level=surface&overlay=wind&product=ecmwf&menu=&message=true&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1'];

//<iframe width="650" height="450" src="https://embed.windy.com/embed2.html?lat=39.402&lon=-77.476&detailLat=40.920&detailLon=-77.473&width=650&height=450&zoom=6&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1" frameborder="0"></iframe>
//<iframe width="650" height="450" src="https://embed.windy.com/embed2.html?lat=27.994&lon=-77.520&detailLat=40.920&detailLon=-77.473&width=650&height=450&zoom=3&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1" frameborder="0"></iframe>
export const iframeSrcArray = [
    { type: 1, location: 'ambler', title: 'Ambler, Pa', src: 'https://embed.windy.com/embed2.html?lat=39.402&lon=-77.476&detailLat=40.920&detailLon=-77.473&width=650&height=450&zoom=6&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1' },
    // 'https://embed.windy.com/embed2.html?lat=40.182&lon=-75.148&detailLat=40.182&detailLon=-75.148&width=650&height=450&zoom=5&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1',
    { type: 1, location: 'ambler', title: 'Ambler, Pa', src: 'https://embed.windy.com/embed2.html?lat=27.994&lon=-77.520&detailLat=40.920&detailLon=-77.473&width=650&height=450&zoom=3&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1' },
    { type: 1, location: 'tamaqua', title: 'Tamaqua, Pa', src: 'https://embed.windy.com/embed2.html?lat=40.378&lon=-75.935&detailLat=40.565&detailLon=-75.935&width=650&height=450&zoom=9&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1' },
    { type: 1, location: 'tamaqua', title: 'Tamaqua, Pa', src: 'https://embed.windy.com/embed2.html?lat=40.591&lon=-75.935&detailLat=40.779&detailLon=-75.222&width=650&height=450&zoom=9&level=surface&overlay=temp&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1' },
    { type: 1, location: 'ventnor', title: 'Ventnor, Nj', src: 'https://embed.windy.com/embed2.html?lat=39.100&lon=-74.539&detailLat=39.292&detailLon=-74.955&width=650&height=450&zoom=9&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1' },
    { type: 1, location: 'ventnor', title: 'Ventnor, Nj', src: 'https://embed.windy.com/embed2.html?lat=39.100&lon=-74.539&detailLat=39.292&detailLon=-74.955&width=650&height=450&zoom=9&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1' },
    { type: 1, location: 'network', title: 'Network', src: 'http://p.artificial2.opalstacked.com/a-network/' },
    { type: 1, location: 'temperatures', title: 'Temperatures', src: 'http://p.artificial2.opalstacked.com/a-temperature/' },
    { type: 2, location: 'money', title: 'Money', src: '' },
    { type: 2, location: 'nicole', title: 'Nicole', src: '' },
    { type: 2, location: 'invoice', title: 'Invoice', src: '' },
    { type: 2, location: 'babysitter', title: 'Baby Sitter', src: '' },
    { type: 2, location: 'words', title: 'Kid Words', src: '' },
    { type: 2, location: 'alarms', title: 'Alarms', src: '' },
    { type: 2, location: 'listOfCounters', title: 'List of Counters', src: '' },
    { type: 1, location: 'supplements', title: 'Supplements', src: 'http://p.artificial2.opalstacked.com/supplements/' },
    { type: 1, location: 'timestables', title: 'Times Tables', src: 'https://www.timestables.com/' },
    { type: 1, location: 'additiontable', title: 'Addition Table', src: 'https://www.mathsisfun.com/numbers/addition-table.html' },
];



export const listOfUsers = [
    { id: "nic", name: "Nic", },
    { id: "wil", name: "Will", }
];

export const listOfMoneyOptions = [
    { id: "half", name: "Half", },
    { id: "all", name: "All", },
    // {id:"none",name:"None",}
];

export const listOfCounters = [
    "Dogs Fed",
    "Dogs Let Out",
    "Dogs Watered",
    "Luna Meds",
    "Tessa Meds",
    "Dominic Meds",
    "Niya Meds",
    "Dominic Score"
    // "Dominic Fed",
    // "Niya Fed",
    // "Niya Changed",
    // "Will Supl",
    // "Nic Supl"
];

// listOfWOD = ['Start','End','Cancel','Mom','Mommy','Dad','Daddy','Continue','Zombies','Brick','Rig','Youtube',
//             'TV','City','Dominic','Niya','Vogler','Jackson','Stop','Go','Up','Down',
//             'Left','Right','Apple','Sandbox','Mode','Pause','Train','Trains','Wooden','Computer',
//             'Join','Hello','Goodbye','Building','Car','Truck','World','Space','Press','Button',
//             'Good','Bad','Join','Lego','Star','Wars','Clone','Cat','Dog','Bird',
//             'Hamster','Sup?','What it do?','Games','Steam','Open','Easy','Normal','Peaceful','Proceed',
//             'Play','Select','Start','Tab','Ready','The','A','Ball','Pacman','Mine',
//             'Craft','Confirm','Snake','God','Jesus','Survival','Freedom',"Tank",
//             'Easy','Loading','Amazon','Terrain','Generating','Done'm];

export const listOfWOD = ['1348', 'the', 'we', 'because', 'see', 'a', 'can', 'like', 'to', 'and', 'go', 'you', 'do', 'my', 'are', 'with', 'he', 'name'];
// listOfWOD = ['is','little','she','was','for','have','of','they','said','want',
//             'here','me', 'this', 'what','help', 'too', 'has', 'play', 'where', 'look', 'good', 'who', 'come', 'does',];

export const listOfMathProblem = ['3 x 5 =', '1 + 1 =', '100 + 100 =', '20 + 20 =', '2 + 2 =', '5 x 5 =', '5 + 5 =', '9 + 9 =', '3 + 3 =', '2 x 2 =',
    '100 x 100 =', '3 - 1 =', '9 / 3 =', '3 x 3 =', '4 + 4 =', '6 + 6 =', '6 + 4 =', '10 x 10 =', '9 * 9 =', '12 * 12 =', '12 / 6 =', '6 / 3 =',
    '2 / 2 =', '1 * 3 =', '1 * 6 =', '1 * 9 =', '1 * 10,000 =', '10 * 3 =', '10 * 5 =', '10 * 50 =', '10 * 12 =']

export const wTotal = 7071.46;//3535.73 * 2 after 9,416.65 before t
export const wPPerWeek = 1767.86;
export const wPPerDay = 252.55;
//   curl https://invoice-generator.com \
//   -H "Content-Type: application/json" \
//   -d '{"from":"Invoiced, Inc.","to":"Acme, Corp.","logo":"https://invoiced.com/img/logo-invoice.png","number":1,"items":[{"name":"Starter plan","quantity":1,"unit_cost":99}],"notes":"Thanks for your business!"}' \
// > invoice.pdf

// invoice001 = {
//   "from":"Invoiced, Inc.",
//   "to":"Acme, Corp.",
//   "logo":"https://invoiced.com/img/logo-invoice.png",
//   "number":1,
//   "items":[
//     {"name":"Starter plan",
//     "quantity":1,
//     "unit_cost":99}
//   ],
//   "notes":"Thanks for your business!"}


//https://github.com/Invoiced/invoice-generator.js
// invoice = {
//   logo: "http://invoiced.com/img/logo-invoice.png",
//   from: "Invoiced\n701 Brazos St\nAustin, TX 78748",
//   to: "Johnny Appleseed",
//   currency: "usd",
//   number: "INV-0001",
//   payment_terms: "Auto-Billed - Do Not Pay",
//   items: [
//       {
//           name: "Subscription to Starter",
//           quantity: 1,
//           unit_cost: 50
//       }
//   ],
//   notes: "Thanks for being an awesome customer!",
//   terms: "No need to submit payment. You will be auto-billed for this invoice.",
//   fields: {
//       tax: "%"
//   },
//   tax: 5,
// };

export const invoice = {
    number: "",
    logo: "http://invoiced.com/img/logo-invoice.png",
    from: "",
    to: "",
    payment_terms: "",
    items: [],
    notes: "",
};

export const invoiceItem = {
    name: "Subscription to Starter",
    quantity: 1,
    unit_cost: 50
}
// }



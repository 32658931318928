import { useEffect, useState } from 'react';
import './todos.scss';
import ApiService from '../serviceApi';

interface Todo {
    userId: number;
    id: number;
    title: string;
    completed: boolean;
}

export const Todos = () => {
    const [todos, setTodos] = useState<Todo[]>([]);
    const [inputTitle, setInputTitle] = useState<string>('');
    const [inputPriority, setInputPriority] = useState<string>('');

    
    const apiService = new ApiService();
    const userId = 1;

    const addTodo = () => {
        const newTodo = { "todo": { "id": todos.length + 1, "title": inputTitle, "completed": false }, "id": 1 }
        apiService.addObject('todos', newTodo)
            .then(data => {
                getAllTodos();
            })
            .catch(error => {
                console.error('Error:', error);
            });
        setInputTitle('');

    };

    const toggleTodo = (id: number) => {
        const updatedTodos = todos.map((todo) => {
            if (todo.id === id) {
                return { ...todo, completed: !todo.completed };
            }
            return todo;
        });
        setTodos(updatedTodos);
    };

    const deleteTodo = (id: number) => {
        apiService.delete('todos', id)
            .then(data => {
                getAllTodos();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const getAllTodos = () => {
        apiService.getAll('todos')
            .then(data => {
                setTodos(data as Todo[]);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        getAllTodos();
    }, []);

    // Add priorities
    return (
        <div>
            {/* <div className='row-container'>
                <span className='title'>Todo:</span>
                <span className='textbox'>
                    <input

                        type="text"
                        value={inputTitle}
                        onChange={(e) => setInputTitle(e.target.value)}
                        placeholder="Add a new todo"
                    />
                </span>
                <span className='title'>Priority:</span>
                <span className='priority'>
                    <input
                        type="number"
                        value={inputTitle}
                        onChange={(e) => setInputTitle(e.target.value)}
                    />
                </span>
                <span className='button'><button onClick={addTodo}>Add</button></span>
            </div> */}
            {/* <div className="todo-container">
                <span className="title">Todo:</span>
                <span className="textbox">
                    <input
                        className='text-text-box'
                        type="text"
                        value={inputTitle}
                        onChange={(e) => setInputTitle(e.target.value)}
                        placeholder="Add a new todo"
                    />
                </span>
                <span className="title">Priority:</span>
                <span className="priority">
                    <input
                        className='priority-text-box'
                        type="number"
                        value={inputPriority}
                        onChange={(e) => setInputPriority(e.target.value)} // Unique handler for number input
                    />
                </span>
                <span className="button">
                    <button onClick={addTodo}>Add</button>
                </span>
            </div> */}

<div className="todo-form">
  <label htmlFor="todoTitle">Todo:</label>
  <input
    type="text"
    id="todoTitle"
    value={inputTitle}
    onChange={(e) => setInputTitle(e.target.value)}
    placeholder="Add a new todo"
  />
  <label htmlFor="priority">Priority:</label>
  <input
    type="number"
    id="priority"
    value={inputPriority}
    onChange={(e) => setInputPriority(e.target.value)}
  />
  <button onClick={addTodo}>Add</button>
</div>

            <ul>
                {todos.map((todo) => (
                    <li key={todo.id}>
                        <span
                            style={{ textDecoration: todo.completed ? 'line-through' : 'none' }}
                            onClick={() => toggleTodo(todo.id)}
                        >
                            {todo.title}
                        </span>
                        <button onClick={() => deleteTodo(todo.id)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};